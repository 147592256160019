// WebDesign.js
import React from 'react';
import '../App.css';
import './Wd.css';

import { Link } from 'react-router-dom';

import picture1 from '../Assets/clement-helardot-95YRwf6CNw8-unsplash.jpg';
import picture3 from '../Assets/towfiqu-barbhuiya-nApaSgkzaxg-unsplash.jpg';

const WdHome = () => {
  return (
    <div>
      <div id="webdesign" className='webdesign-background'>
        <div className="wd-container">
          <div className="home-banner-container">
            <div className="home-text-section">
              <h1 className="primary-heading">
                Web Design
              </h1>
              <p className="home-text">
                <i>Crafting Compelling Digital Destinations: Elevating Your Online Presence Through Expert Web Design.</i>
              </p>
              <div className='wd-home-content'>
                <p>
                We redefine web development with a seamless blend of creativity and functionality. Our mission is clear: to create visually appealing, user-friendly web applications tailored to your unique needs.
                <br/><br/>At our core is a commitment to understanding your business. We don't just build websites, we embark on a journey to comprehend your brand, goals, and audience, ensuring an authentic reflection in the web app we develop.
                </p>
                <img src={picture1} alt="work" className="wd-home-image" />
              </div>
              <div className='wd-home-content'>
                <img src={picture3} alt="work" className="wd-home-image" />
                Our team of skilled developers prioritizes user-friendly interfaces, employing the latest design principles for functional and enjoyable experiences.
                <br/> <br/>Ready to elevate your online presence? Let's talk. Our team is here to turn your vision into a captivating reality, exceeding your expectations. Ready to make your mark in the digital realm? The next chapter of your online success awaits – let's create something extraordinary.              </div>
            </div>
          </div>
        </div>
        <div className="get-started-button-container">
                <Link to="/contact" className="get-started-button">
                  <strong>Get free consultation</strong>
                </Link>
              </div>
      </div>
    </div>
  );
}

export default WdHome;
