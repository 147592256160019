import React, { useRef } from 'react';
import './Contact.css';

import emailjs from '@emailjs/browser';
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_mq3b8zh', 'template_c2ycnkp', form.current, {
        publicKey: 'DFyP4CpOCT2uEmFQF',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div id="contact" className="contact-container">
      <div className="contact-info">
      <h1>Contact Us</h1>
        <p>
          Feel free to reach out to us for any inquiries or to schedule a consultation. We are here to assist you!
        </p>
        <div className="contact-details">
          <div>
            <h3><IoMdMail className='email-icon'/>Email</h3>
            <p>mail@silver-sites.com</p>
          </div>
          <div>
            <h3><FaPhoneAlt className='phone-icon'/> Phone</h3>
            <p>+31 6 26 26 01 07</p>
            <p>+34 631 466 323</p>
          </div>
        </div>
      </div>

      <div className="consultation-form">
        <h2>Apply for Consultation</h2>

        <form ref={form} onSubmit={sendEmail}>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="user_name" required/>

          <label>Email:</label>
          <input type="email" id="email" name="user_email" required />

          <label htmlFor="phone">Phone:</label>
          <input type="tel" id="phone" name="user_phone" required />

          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" rows="4" required></textarea>

          <button type="submit">SEND</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;

/*
import React from 'react';


const Contact = () => {

  return (
      <div className="consultation-form">
        <h2>Apply for Consultation</h2>
        <form>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="user_name" required />

          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="user_email" required />

          <label htmlFor="phone">Phone:</label>
          <input type="tel" id="phone" name="user_phone" required />

          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" rows="4" required></textarea>

          <©
        </form>
      </div>
    </div>
  );
}

export default Contact;
*/