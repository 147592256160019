import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import WebDesign from './WebDesign'
import Hosting from './Hosting'
import About from './Components/About';
import Contact from './Components/Contact';
import Services from './Components/Services';
import Navbar from './HsComponents/HsNavbar';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/web-design",
    element: <WebDesign />
  },
  {
    path: "/hosting",
    element: <Hosting />
  },
  {
    path: "/about",
    element: <About />
  },
  {
    path: "/services",
    element: <Services />
  },
  {
    path: "/contact",
    element: <Contact />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Navbar />
    <RouterProvider router={router} />  
  </>
);

