import React from 'react';
import './About.css';

import picture2 from '../Assets/markus-winkler-IrRbSND5EUc-unsplash.jpg';
import picture4 from '../Assets/tyler-franta-iusJ25iYu1c-unsplash.jpg';
import picture6 from '../Assets/marvin-meyer-SYTO3xs06fU-unsplash.jpg';

import { CiClock2 } from "react-icons/ci";
import { IoMdThumbsUp } from "react-icons/io";
import { BiWorld } from "react-icons/bi";

const About = () => {
  return (
    <div id="about" className="about-container">

      <h1>About Us</h1>
      <div className="about-content">
        <div className="about-columns">
          <img src={picture6} alt="Team" className="about-image" />
          <p className="about-para1">
          At Silver Sites, we go beyond providing mere solutions – we empower individuals and businesses with personalized, high-impact digital transformations. Our devoted team is driven by a mission to deliver not just seamless development but also sustained operational excellence through ongoing maintenance services. Trust us to turn your vision into a digital reality that not only meets your needs today but sets the stage for your future success. Let's embark on a journey where innovation meets dedication, creating a digital landscape tailored uniquely to your aspirations. Your success story starts with Silver Sites.
          </p>
        </div>
      </div>

      <div id="hire-us" className="hire-container">
        <h2>Why Hire Us?</h2>
        <div className="hire-content">
          <div className="hire-icons">
            <CiClock2 /> 24 hour availability
            <IoMdThumbsUp /> Reliable service
            <BiWorld /> International support
          </div>
        </div>
        <div className="hire-content">
          <p>
            Our team is dedicated to bringing your ideas to life through captivating designs and seamless user experiences. Whether you're looking to establish your online presence or enhance your existing digital platform, we're here to turn your vision into reality.
          </p>
        </div>
          <img src={picture4} alt="work" className="hire-image" />
      </div>

      <div id="benefits" className="benefits-container">
      <h2>Benefits We Bring</h2>
      <div className="benefits-content">
        
        <div className="benefits-boxes">
          <div className="benefit-box">
            <h3>Reach more customers.</h3>
            <p>Unlock your business potential with our optimized websites—where captivating design meets strategic functionality.  Elevate your customer reach and engagement, turning every click into a meaningful connection that propels your business forward. Let's optimize your online presence for success!</p>
          </div>
          <div className="benefit-box">
            <h3>Reliable crew.</h3>
            <p>Our dedicated team is at your service 24/7, ensuring prompt assistance whenever you need it. With an unwavering work ethic and a commitment to flexibility. Your success is our priority, and we're ready to go the extra mile to achieve it together.</p>
          </div>
          <div className="benefit-box">
            <h3>Unique tailored products.</h3>
            <p>Experience innovation personalized just for you. Our team crafts one-of-a-kind solutions that align with your distinct requirements, ensuring your digital footprint is as unique as your brand. Creating digital products that set you apart in a world of endless possibilities.</p>
          </div>
        </div>
        <div className="benefits-columns">
          <p>
          We center our efforts on your satisfaction, pledging not only to deliver high-quality websites but also to provide relentless support, ensuring a seamless path to your online triumph. We invite you to embark on this collaborative journey with us, where innovation and commitment converge to build something extraordinary. Let's create a digital presence that goes beyond expectations, solidifying your mark in the online landscape. Join forces with us – your success story begins here.
          </p>
          <img src={picture2} alt="data" className="benefits-image" />
        </div>
      </div>
    </div>
    </div>
  );
}

export default About;
