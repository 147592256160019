import React from 'react';
import './App.css';
import HsHome from './HsComponents/HsHome';
import HsNavbar from './HsComponents/HsNavbar';
import Footer from './Components/Footer';

const Hosting = () => {
  return (
    <div className="App">
        <HsNavbar/>
        <HsHome />
        <Footer/>
    </div>
  );
}

export default Hosting;
