import React from 'react'
import './Home.css';

const Home = () => {
return (
    <div id="home" className='home-background'>
        <div className="box">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            
          </ul>
        </div>

        <div className="home-container">
            <div className="home-banner-container">
                <div className="home-text-section">
                <h1 className="primary-heading">
                    Empower your online presence with Silver Sites – <b>Crafting Digital Excellence for Your Business.</b>
                </h1>
                <p className="home-text">
                    <i>From captivating designs to seamless user experiences, we take pride in turning your ideas into engaging digital platforms.</i>
                </p>
                </div>
                <div className="home-image-section">
                </div>
            </div>
        </div>
    </div>
    )
}

export default Home
