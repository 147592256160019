import React, { useState } from 'react';
import logo from '../Assets/silverSitesWhite.png'

const HsNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
    setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
        <div className="navbar-container">
           
            <a href="/" className="nav-links" onClick={toggleNavbar}>
                <div className="navbar-logo">
                    <img src={logo} alt="logo" />
                </div>
            </a>
        
            <div className={`menu-icon ${isOpen && 'active'}`} onClick={toggleNavbar}>
                <i className="fas fa-bars" />
            </div>
    
            <ul className={`nav-menu ${isOpen && 'active'}`}>
                <li className="nav-item">
                    <a href="/" className="nav-links" onClick={toggleNavbar}>
                    Go Back
                    </a>
                </li>

                {/* <li className="nav-item">
                    <Link to="/services" className="nav-links" onClick={toggleNavbar}>
                    Services
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/about" className="nav-links" onClick={toggleNavbar}>
                    About Us
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/contact" className="nav-links" onClick={toggleNavbar}>
                    Contact
                    </Link>
                </li> */}
            </ul>
        </div>
        </nav>
    );
};


export default HsNavbar
