import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import picture1 from '../Assets/andrew-neel-cckf4TsHAuw-unsplash.jpg';
import picture3 from '../Assets/roman-bozhko-PypjzKTUqLo-unsplash.jpg';

const Hosting = () => {
  return (
    <div id="webdesign" className='webdesign-background'>
      <div className="wd-container">
        <div className="home-banner-container">
          <div className="home-text-section">
            <h1 className="primary-heading">
              Hosting & Scalability
            </h1>
            <p className="home-text">
              <i>Experience hosting solutions that effortlessly scale with your growing needs, ensuring optimal performance and reliability for your web applications.</i>
            </p>
            <div className='wd-home-content'>
            <img src={picture1} alt="work" className="wd-home-image" />
              <p>
              Welcome to Silver Sites, where we go the extra mile for your website. Our extended service ensures your website stays stunning, seamless, and scalable. With monthly subscriptions, we handle maintenance, updates, and proactive management.
              </p>
            </div>
            <div className='wd-home-content'>
              <p>
              Experience worry-free digital growth with our commitment to scalability, adapting to your business needs. Elevate your online presence stress-free – let's talk about making your website exceed expectations, today and into the future. Ready for worry-free digital excellence? Let's take the next step together.
              </p>
                <img src={picture3} alt="work" className="wd-home-image" />
            </div>
          </div>
        </div>
      </div>
        <div className="get-started-button-container">
                <Link to="/contact" className="get-started-button">
                  <strong>Get free consultation</strong>
                </Link>
              </div>
      </div>
  );
}

export default Hosting;
