import React, { useState } from 'react';
import './Navbar.css';

import logo from '../Assets/silverSitesWhite.png'
import { Link } from 'react-scroll';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link
                    to="home"
                    smooth={true}
                    duration={500}
                    className="nav-links"
                    onClick={toggleNavbar}
                >
                    <div href="/" className="navbar-logo">
                        <img src={logo} alt="logo" />
                    </div>
                </Link>

                <div className={`menu-icon ${isOpen && 'active'}`} onClick={toggleNavbar}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <ul className={`nav-menu ${isOpen && 'active'}`}>
                    <li className="nav-item">
                        <Link
                            to="home"
                            smooth={true}
                            duration={500}
                            className="nav-links"
                            onClick={toggleNavbar}
                        >
                            Home
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="services"
                            smooth={true}
                            duration={500}
                            className="nav-links"
                            onClick={toggleNavbar}
                        >
                            Services
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="about"
                            smooth={true}
                            duration={500}
                            className="nav-links"
                            onClick={toggleNavbar}
                        >
                            About Us
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="contact"
                            smooth={true}
                            duration={500}
                            className="nav-links"
                            onClick={toggleNavbar}
                        >
                            Contact
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
