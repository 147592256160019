import React from 'react'
import './Footer.css';

import { FaGithub } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-icons">
          <SiLinkedin />
          <FaGithub />
        </div>
        <p>All rights reserved.</p>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span>Quality</span>
          <span>Help</span>
          <span>Work</span>
        </div>
        <div className="footer-section-columns">
          <span>(6) 26 26 01 07</span>
          <span>mail@silver-sites.com</span>
          <span>press@silver-sites.com</span>
        </div>
        <div className="footer-section-columns">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
          <span>Cookies</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;