import React from 'react';
import './App.css';
import WdNavbar from './WdComponents/WdNavbar';
import WdHome from './WdComponents/WdHome';
import Footer from './Components/Footer';

const WebDesign = () => {
  return (
    <div className="App">
      <WdNavbar/>
      <WdHome/>
      <Footer/>
    </div>
  );
}

export default WebDesign;
