import React from 'react';
import './Services.css';

import { FiLayers, FiCode } from "react-icons/fi";
import { FaPaintBrush } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FaLock } from "react-icons/fa";

const Services = () => {
  return (
    <div id="services" className="services-container">
      <h1>Our Services</h1>
      <div className="services-content">
      <Link to="/web-design" className="services-link">
        <div className="services-box">
          <FaPaintBrush rotation={180} size={50} bounce/>
          <h3>Web Design</h3>
          <p>Craft your online identity with bespoke web apps tailored to your brand. We blend creativity and functionality for a captivating user experience.</p>
          <h4>Click to learn more</h4>
        </div>
        </Link>
        <Link to="/hosting" className="services-link">
        <div className="services-box">
          <FiLayers size={50} color="#fff" />
          <h3>Hosting & Scalability</h3>
          <p>Elevate your digital presence hassle-free! Our monthly service ensures your website stays secure, updated, and scalable, letting you focus on what you do best.</p>
          <h4>Click to learn more</h4>
        </div>
        </Link>
        <div className="services-box construction">
          <div className="overlay">
            <FaLock size={30} />
            <p><b>Coming Soon</b></p>
          </div>
          <FiCode size={50} color="#fff" />
          <h3>Marketing Solutions</h3>
          <p>Provide unique and tailored digital solutions to meet your specific needs.</p>
        </div>
      </div>
    </div>
  );
}

export default Services;
